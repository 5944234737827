<template>
  <div>
    <div class="d-flex accordion-title border-bottom">
      <div class="accordion-button d-inline-flex text-uppercase" @click="toggleActive">
        {{ group.replace("_", " ") }}
      </div>
      <input v-model="selectAll" type="checkbox" @change="onChangeGroupValue">
    </div>
    <div v-show="activeItem">
      <div v-for="item in filteredResourceList" :key="item.resource.id" class="">
        <div class="d-flex w-100">
          <div class="accordion-content w-100">
            <div class="pl-3">
              <div class="text-info" style="font-size: 16px">
                {{ item.resource.description }}
              </div>
              <small>
                {{ item.resource.name }}
              </small>
            </div>
            <input v-model="item.read" type="checkbox" :data-id="item.resource.id" @change="onChangeValue">
          </div>
        </div>
      </div>
      <div class="border-bottom"></div>
    </div>
  </div>
</template>

<script>
import userManagementService from '@/http/requests/system/userManagementService'

export default {
  props: {
    group: {
      type: String,
      default: '',
    },
    permissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeItem: false,
      selectAll: false,
    }
  },
  computed: {
    filteredResourceList() {
      return this.permissions.filter(permission => permission.resource.groupName === this.group)
    },
  },
  watch: {
    filteredResourceList: {
      immediate: true,
      deep: true,
      handler() {
        const allTrue = this.filteredResourceList.every(item => item.read === true)
        const allFalse = this.filteredResourceList.every(item => item.read === false)
        // eslint-disable-next-line no-nested-ternary
        this.selectAll = allTrue ? true : allFalse ? false : null
      },
    },
  },
  methods: {
    toggleActive() {
      this.activeItem = !this.activeItem
    },
    onChangeValue(e) {
      const itemId = parseInt(e.target.dataset.id, 10)
      const itemIndex = this.filteredResourceList.findIndex(item => item.resource.id === itemId)
      if (itemIndex !== -1) {
        this.filteredResourceList[itemIndex].read = e.target.checked
        userManagementService.createRoleResourceAction(this.permissions)
      }
    },
    onChangeGroupValue(e) {
      this.filteredResourceList.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.read = e.target.checked
      })
      userManagementService.createRoleResourceAction(this.permissions)
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion-title {
  padding-inline: 12px;
}
.accordion-button {
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  margin: 4px ;
}

.accordion-content {
  padding: 12px;
  height: auto;
  display: flex;
  justify-content: space-between;
  transition: max-height 0.2s ease-out;
}
input {
  width: 20px;
}
</style>
