<template>
  <div class="container card px-0">
    <div class="row px-0">
      <div class="col-12">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-collection-fill" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Role Setup
              </h4>
              <p class="card-text mb-0">
                Set Permissions for {{ roleName }}
              </p>
            </div>
          </div>
        </div>
        <div v-for="group in groupedResources" :key="group">
          <PermissionsGroup :group="group" :permissions="rolePermissions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userManagementService from '@/http/requests/system/userManagementService'
import PermissionsGroup from '@/views/apps/permission/roles/roles-permission/PermissionsGroup.vue'

export default {
  components: {
    PermissionsGroup,
  },
  data() {
    return {
      rolePermissions: [],
      groupedResources: [],
    }
  },
  computed: {
    roleId() {
      return this.$route.params.roleId
    },
    roleName() {
      return this.$route.params.roleName
    },
  },
  created() {
    this.getRoleResourcesByRoleId()
  },
  methods: {
    getRoleResourcesByRoleId() {
      this.rolePermissions = []
      userManagementService
        .getRoleResourceActionByRoleId(this.roleId)
        .then(result => {
          const { data } = result
          if (data && data.length > 0) {
            this.rolePermissions = data
          }
          this.groupedResources = [...new Set(data.map(item => item.resource.groupName))]
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
